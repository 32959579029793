.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ellipse {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden !important;
    text-transform: capitalize;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.one-line {
    -webkit-line-clamp: 1;
}

.two-lines {
    -webkit-line-clamp: 2;
}

.three-lines {
    -webkit-line-clamp: 3;
}

.four-lines {
    -webkit-line-clamp: 4;
}

.hover-underline:hover {
    text-decoration: underline;
}

.scroll-x-on-hover:hover {
    overflow-x: auto;
}

.app-row {
    display: row;
    flex-direction: row;
}

.overflow-hover:hover {
    overflow-x: "auto",
}

.custom-scroll::-webkit-scrollbar {
    width: 20px;
}

.custom-scroll::-webkit-scrollbar-track {
    background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.row-left {
    flex-basis: 70%;
    overflow-x: auto;
}

.box {
    padding: 10px;
    margin-left: 5px;
}

.no-scroll {
    overflow-y: hidden;
}

@media only screen and (max-width: 900px) {
    .app-row {
        flex-direction: column;
    }
}


/* Works on Firefox */